import React, { useEffect, useState } from "react";
import "./FinishOnboarding.css";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { ChargerStore, handleGetOnboardingConnectorsForCharger, handleGetConnectorTypes, handleUpdateOnboardingConnector, handleFinishOnboardingCharger } from "../../../../stores/chargerStore";
import { handleGetPricingGroupsForDropdown } from "../../../../stores/pricingStore";
import { useStore } from "react-stores";
import { useNavigate } from "react-router-dom";
import SingleValue from "react-select/dist/declarations/src/components/SingleValue";

interface Connector {
    connectorId: number | undefined,
    connectorTypeId: number | undefined,
    connectorTypeName: string,
    pricingGroupId: number | undefined,
    pricingGroupName: string,
    maxPower: number | undefined
}
interface DropdownElement {
    value: number,
    label: string
}


const FinishOnboardingPage = () => {
    const { id, companyMarketId } = useParams();
    const [connectorTypesDropdown, setConnectorTypesDropdown] = useState<DropdownElement[]>([]);
    const [pricingGroupsDropdown, setPricingGroupsDropdown] = useState<DropdownElement[]>([]);
    const { onboardingConnectorsForCharger } = useStore(ChargerStore);
    const [confirmApproved, setConfirmApproved] = useState(false);
    const [connectors, setConnectors] = useState<Connector[]>([]);
    const navigate = useNavigate();

    const selectStyles = {
        control: (styles, state) => ({
            ...styles,
            "&:hover": {
                border: state.isDisabled ? "0px" : "2px solid #111F47"
            },
            boxShadow: 'none',
            fontSize: '14px',
            fontWeight: '500',
            color: '#111F47 !important',
            fontFamily: "'Inter'",
            borderRadius: "10px",
            height: '44px',
            border: state.isDisabled ? "0px" : (state.isFocused ? "2px solid #111F47" : "2px solid #B5BAC6"),
            background: state.isDisabled ? '#E7E9ED80' : 'white'

        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            "&:hover": {
                background: "#E9EDFC"
            },
            background: state.isSelected ? "#E9EDFC" : "#FAFBFB",
            fontSize: '12px',
            fontWeight: '500',
            fontFamily: "'Inter'",
            color: "#111F47",
            borderRadius: "10px",
            border: "4px solid white",
            padding: "10px",
        })
    };

    useEffect(() => {
        const getData = async () => {
            setConnectors(await handleGetOnboardingConnectorsForCharger(Number(id)));
            setConnectorTypesDropdown(await handleGetConnectorTypes().then(res => res?.map(type => { return { value: type.id, label: type.name }; })));
            setPricingGroupsDropdown(await handleGetPricingGroupsForDropdown([Number(companyMarketId)]).then(res => res?.map(group => { return { value: group.id, label: group.name }; })));
        }

        getData()
    }, [companyMarketId, id]);

    useEffect(() => {
        let canConfirm = true;
        for (let connector of connectors) {
            if (!(connector.connectorId && connector.connectorTypeId && connector.pricingGroupId && connector.maxPower)) {
                canConfirm = false;
                break;
            }
        }
        canConfirm ? setConfirmApproved(true) : setConfirmApproved(false);
    }, [connectors])


    const handleConfirmation = async () => {
        for (let connector of connectors) {
            await handleUpdateOnboardingConnector(JSON.stringify({
                connectorId: connector.connectorId,
                connectorTypeId: connector.connectorTypeId,
                pricingGroupId: connector.pricingGroupId,
                maxPower: connector.maxPower
            }));
        }
        await handleFinishOnboardingCharger(Number(id));
        navigate("/dashboard/network/onboarding");
    }

    return (
        <div className="flex-1 p-5 flex flex-col gap-5 items-stretch main-wrapper">
            <div className="bg-white card p-5">
                <div className="flex flex-row justify-between items-center header flex-wrap">
                    <div className="flex flex-row justify-start items-center gap-3">
                        <Link to="/dashboard/network/onboarding">
                            <img src="/icons/arrow/arrowLeft.svg" className="w-[24px] h-[24px]" alt="" />
                        </Link>
                        <div className="finish-onboarding-span">
                            Finish Onboarding
                        </div>
                    </div>
                    <button
                        disabled={!confirmApproved}
                        onClick={() => handleConfirmation()}
                        className={`confirmButton flex flex-row gap-3 items-center justify-center ${confirmApproved ? "confirmButtonActive" : ""}`}
                    >
                        Confirm
                    </button>

                </div>
            </div>
            <div className="bg-white card p-8">
                {connectors.map((connector, index) => (
                    <div className="pb-9 flex flex-col justify-start items-stretch" key={connector.connectorId}>
                        <div className="flex flex-col justify-between items-center gap-4">
                            <div className="connector-header">
                                Connector #{index + 1}
                            </div>
                            <div className="flex flex-row items-center justify-between w-full">
                                <div className="flex flex-col justify-start items-stretch gap-2">
                                    <div className="connector-id">Connector ID</div>
                                    <input type="text" value={onboardingConnectorsForCharger[index] && onboardingConnectorsForCharger[index].connectorId} className="connector-id-input-readonly" readOnly></input>
                                </div>
                                <div className="flex flex-col justify-start items-stretch gap-2">
                                    <div className="connector-id">Connector Type</div>
                                    <Select
                                        defaultValue={onboardingConnectorsForCharger[index] && { label: onboardingConnectorsForCharger[index].connectorType, value: onboardingConnectorsForCharger[index].connectorId }}
                                        options={connectorTypesDropdown}
                                        isDisabled={onboardingConnectorsForCharger[index].connectorTypeId !== undefined}
                                        styles={selectStyles}
                                        onChange={(selectedOption) => {
                                            let arr = [...connectors];
                                            arr[index] = { ...connector, connectorTypeId: selectedOption?.value || undefined, connectorTypeName: selectedOption?.label || "" };
                                            setConnectors(arr);
                                        }}
                                    />
                                </div>
                                <div className="flex flex-col justify-start items-stretch gap-2">
                                    <div className="connector-id">Pricing Group Name</div>
                                    <Select
                                        defaultValue={onboardingConnectorsForCharger[index] && { label: onboardingConnectorsForCharger[index].pricingGroup, value: onboardingConnectorsForCharger[index].pricingGroupId }}
                                        isDisabled={onboardingConnectorsForCharger[index].pricingGroupId !== undefined}
                                        options={pricingGroupsDropdown}
                                        styles={selectStyles}
                                        onChange={(selectedOption) => {
                                            let arr = [...connectors];
                                            arr[index] = { ...connector, pricingGroupId: selectedOption?.value || undefined, pricingGroupName: selectedOption?.label || "" };
                                            setConnectors(arr);
                                        }}
                                    />
                                </div>
                                <div className="flex flex-col justify-start items-stretch gap-2">
                                    <div className="connector-id">Max Power (kW)</div>
                                    <input type="number" className={`${onboardingConnectorsForCharger[index].maxPower ? "connector-id-input-readonly" : "connector-id-input"}`}
                                        value={connector.maxPower}
                                        readOnly={onboardingConnectorsForCharger[index].maxPower !== undefined}
                                        onChange={(e) => {
                                            if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {
                                                let arr = [...connectors];
                                                arr[index] = { ...connector, maxPower: Number(e.target.value) || undefined };
                                                setConnectors(arr);
                                            }
                                        }}></input>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FinishOnboardingPage;
